import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Meta from '../components/Meta'
import Layout from '../components/Layout'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(
        relativePath: { eq: "services/grounds-maintenance-banner-1.webp" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="Grounds Maintenance"
        description="Our grounds maintenance teams cover Berkshire and Surrey, our managed premises include student villages, business parks, and large private residences."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="lg:flex justify-between bg-gray-lightest border-b-8 border-green">
          <div className="grid justify-center content-center mx-auto py-12 px-8 lg:py-16 lg:px-12">
            <h2 className="uppercase text-4xl font-extrabold sm:text-5xl">
              Grounds maintenance
            </h2>
          </div>
          <div className="flex-1 max-w-7xl">
            <StaticImage
              src="../images/services/grounds-maintenance-banner-1.webp"
              alt="Featured property"
              className="aspect-w-16 aspect-h-7"
              aspectRatio={16 / 7}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
          </div>
        </section>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                Grounds Maintenance
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <StaticImage
                      src="../images/services/grounds-maintenance-feature-1.webp"
                      alt="Featured work"
                      className="aspect-w-1 aspect-h-1"
                      aspectRatio={1}
                      placeholder="blurred"
                      layout="fullWidth"
                      transformOptions={{ cropFocus: 'entropy' }}
                    />
                    <figcaption className="mt-3 flex text-sm text-gray-light" />
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    We believe that we provide a quality and efficiency in our
                    grounds maintenance service that larger firms cannot compete
                    with. Our grounds maintenance teams cover Berkshire and
                    Surrey, our managed premises include student villages,
                    business parks, and large private residences. We continually
                    invest in equipment that can facilitate large areas at an
                    unfaltering quality to create beautiful landscapes all year
                    round.
                  </p>
                </div>
                <div className="mt-5 prose prose-indigo text-gray-light mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <ul>
                    <li>Grass cutting and Mowing</li>
                    <li>Hedge cutting and Maintenance</li>
                    <li>Tree works</li>
                    <li>Weed control</li>
                    <li>Sports fields</li>
                    <li>Winter gritting</li>
                  </ul>
                  <h4>Fully Insured up to &pound;5,000,000</h4>
                  <p>
                    We are fully insured with public and employee liability and
                    able to work on commercial sites. Documents are provided on
                    request and ProGro will adhere to the client specific
                    requirements.
                  </p>
                  <p>
                    If you need a maintenance team that consistently delivers
                    quality and reliability, please give us a call to talk about
                    how we can help.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
